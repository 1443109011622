<template>
  <div style="margin-top: 30px">
    <SectionTitle :title="useStorePermission ? '區間內統計' : '回饋金紀錄列表'" fontSize="18" hideBtn />
    <div class="record__datetime">
      <RangeTimeSelect
        @custom="handleTimeRangeChange"
        @month="handleTimeRangeChange"
        @week="handleTimeRangeChange"
        @today="handleTimeRangeChange"
      />
    </div>

    <SectionTitle
      title="總覽"
      fontSize="18"
      hideBtn
      hideTitleStick
      style="margin-top: 20px"
    />
    <TimeRangeStatistic :dateRange="curDate" />

    <RebateRecordStoreTable v-if="false" :dateRange="curDate" />

    <SectionTitle
      title="回饋金紀錄列表"
      fontSize="18"
      hideBtn
      style="margin-top: 20px"
    />
    <FiltersContainer class="mt-[20px]">
      <MemberSearch :model.sync="search.member" @change="refresh" />
      <BaseElSelect v-model="search.origin" clearable placeholder="請選擇形式" @change="refresh">
        <BaseElSelectOption
          v-for="option in originConfig"
          :key="option.value"
          :label="option.name"
          :value="option.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <BaseTable v-loading="loading.table" :data="tableData">
      <BaseElTableColumn prop="Member.UserInfo.name" label="姓名" align="center" />
      <BaseElTableColumn prop="Member.UserInfo.phone" label="電話號碼" align="center" />
      <BaseElTableColumn prop="origin" label="形式" align="center">
        <template slot-scope="scope">
          <div>{{ get(originConfig, `${scope.row.origin}.name`) || '-' }}</div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="amount" label="金額" align="center">
        <template slot-scope="scope">
          <div><span v-if="scope.row.amount > 0">+</span>{{ scope.row.amount }}</div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="建立時間" align="center">
        <template slot-scope="scope">
          <div>{{ dateFormat(scope.row.createdAt) }}</div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="false" props="Branch" label="門市" align="center">
        <template slot-scope="scope">
          {{ scope.row.Branch?.name || "-" }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="originMessage" label="備註" align="center" />
    </BaseTable>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(curDate)"
    />

    <ExportOptionsDialog
      v-if="dialog.exportOptions"
      allRange
      :useExportTask="useExportTask"
      @close="dialog.exportOptions = false"
      @export="dialog.exportOptions = false, prepareExport()"
    />
    <ExportDialog
      v-if="exportState.exportting"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :percentage="exportState.percentage"
      :data="exportState.exportData"
      :total="exportState.dataCount"
      @close="resetExport"
    />
  </div>
</template>

<script setup>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import RangeTimeSelect from '@/components/Report/RangeTimeSelect.vue'
import TimeRangeStatistic from './TimeRangeStatistic.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import RebateRecordStoreTable from './RebateRecordStoreTable'
import { GetRebateRecord, GetRebateRecordCount } from '@/api/rebate'
import { CreateShopCashbackReportSheetExportTask } from '@/api/exportTask'
import { onMounted, ref, computed } from 'vue'
import dayjs from '@/lib/dayjs'
import { get } from 'lodash'
import { useRangeTime } from '@/use/rangeTime'
import { useTable } from '@/use/table'
import { useExport } from '@/use/export'
import { originConfig } from '@/config/rebate'
import { useVModels } from '@vueuse/core'
import { usePermissions } from '@/use/permissions'
import { useExportCenter } from '@/use/useExportCenter'

const props = defineProps({
  dialog: {
    type: Object,
    default: () => ({
      exportOptions: false,
      exporting: false,
    }),
  },
})

const emit = defineEmits(['update:dialog'])

const { search, loading, shopId, dateFormat, pageStartIndex, extendData, tableOptions, tableData, tableDataCount } = useTable()
const { getTodayRange } = useRangeTime()
const { exportState, resetExport, ExportExcel } = useExport()
const { dialog } = useVModels(props, emit)
const { checkAction } = usePermissions()
const { messageOptions } = useExportCenter()

extendData('search', {
  origin: null,
  member: null,
})

extendData('dialog', {
  exportOptions: false,
  exporting: false,
})
const curDate = ref(getTodayRange())
const useExportTask = computed(() => checkAction('admin.shopCashbackReport.createSheetExportTask'))
const handleTimeRangeChange = async (e) => {
  tableOptions.page = 1
  await refresh(e)
}

const getRebateRecord = async (date) => {
  const [res, err] = await GetRebateRecord({
    shopId: shopId.value,
    createStart: dayjs(date[0]).toDate(),
    createEnd: dayjs(date[1]).toDate(),
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    origin: get(search, 'origin') || undefined,
    memberId: get(search, 'member.id') || undefined,
  })
  if (err) return (window.$message.error(err))
  tableData.value = res
}

const getRebateRecordCount = async (date) => {
  const [res, err] = await GetRebateRecordCount({
    shopId: shopId.value,
    createStart: dayjs(date[0]).toDate(),
    createEnd: dayjs(date[1]).toDate(),
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    origin: search.origin || undefined,
    memberId: get(search, 'member.id') || undefined,
  })
  if (err) return (window.$message.error(err))
  tableDataCount.value = res.count
}

const refresh = async (e) => {
  loading.table = true
  let date = get(e, 'date')
  if (date) curDate.value = date
  if (!date) date = curDate.value
  await Promise.all([
    getRebateRecord(date),
    getRebateRecordCount(date),
  ])
  loading.table = false
}

const getExportData = async () => {
  const dataContainer = []
  const payload = {
    shopId: shopId.value,
    start: pageStartIndex.value,
    limit: tableOptions.pageLimit,
    createStart: dayjs(curDate.value[0]).toDate(),
    createEnd: dayjs(curDate.value[1]).toDate(),
    origin: get(search, '.origin') || undefined,
    memberId: get(search, 'member.id') || undefined,
  }
  while (dataContainer.length < exportState.dataCount) {
    const [res, err] = await GetRebateRecord(payload)
    if (err) {
      window.$message.error(err)
      return
    }
    dataContainer.push(...res)
    payload.start += payload.limit
  }
  return dataContainer
}

const formatExportData = () => {
  if (!exportState.exportData) return
  console.log(exportState.exportData)

  const formatData = []
  let count = 0
  exportState.exportData.forEach((item) => {
    const row = {
      姓名: get(item, 'Member.UserInfo.name') || '-',
      電話號碼: get(item, 'Member.UserInfo.phone') || '-',
      形式: get(originConfig, `${item.origin}.name`) || '-',
      金額: item.amount,
      建立時間: dateFormat(item.createdAt),
      備註: item.originMessage || '-',
    }
    formatData.push(row)
    count++
    exportState.percentage = (count / exportState.dataCount) * 100
  })
  ExportExcel(formatData, '回饋金報表', '回饋金報表')
}

const prepareExport = async () => {
  if (useExportTask.value) {
    const [, err] = await CreateShopCashbackReportSheetExportTask({
      shopId: shopId.value,
      createdAtStart: dayjs(curDate.value[0]).toDate(),
      createdAtEnd: dayjs(curDate.value[1]).toDate(),
      origin: get(search, 'origin') || undefined,
      memberId: get(search, 'member.id') || undefined,
    })
    if (err) {
      window.$message.error(err)
      return
    }
    window.$message(messageOptions.value)
  } else {
    resetExport()
    // 取得匯出數據數量
    exportState.dataCount = tableDataCount.value
    if (!exportState.dataCount) {
      exportState.error = true
      window.$message.warning('沒有資料可以匯出')
      return null
    }
    dialog.exporting = true
    exportState.exportting = true

    // get export data
    exportState.exportData = await getExportData()
    formatExportData()
  }
}

const useStorePermission = computed(() => {
  return checkAction('admin.branch.adminUseStore')
})

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
